import React from 'react'
import Head from 'next/head'

/**
 * Error page component
 */
const Error = ({ statusCode }: { statusCode: string }) => {
  const errorHeading = statusCode ? `${statusCode} Error` : 'Client-side error'
  const errorMessage =
    statusCode === '404'
      ? 'Document not found'
      : 'Please contact your development team'

  return (
    <div className="not-found">
      <Head>
        <title>Error!</title>
      </Head>
      <h1 className="text-2xl">{errorHeading}</h1>
      <h2>{errorMessage}</h2>
      <p>
        <a className="text-blue-500 hover:text-blue-800" href="/">
          Return to homepage
        </a>
      </p>
    </div>
  )
}

export default Error
