import React from 'react'
import Error from './_error'
/**
 * Error page component
 */
const Custom404 = () => {
  return <Error statusCode="404" />
}

export default Custom404
